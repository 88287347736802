
import * as entryModule from '/builds/reporting-n-viz/reporting-ui-framework/ai-firewall-ui/src/index.tsx';
function run(name, genid, globalVar, dev, module, sig) {
  if (globalVar) {
    if (window[globalVar]) {
      console.warn(`another micro-app with same global variable ${globalVar} already exist`)
    }
    else {
      window[globalVar] = module
    }
  }
  const curScript = window.document.currentScript ||
    (dev && window.document.querySelector(`script[data-micro-app='${name}']:not([src])`))
  if (curScript) {
    const { microAppId, generatedId } = curScript
    if ((dev && !curScript.hasAttribute('src') ||
      (generatedId && genid ? generatedId === genid : (microAppId && microAppId === name)))) {
      curScript.__ep = module
      curScript.dispatchEvent(new CustomEvent('micro-app-ep', { detail: module }))
      if (!dev) {
        return // only dev mode always has fallback
      }
    }
    else if (!dev && !globalVar && generatedId && genid) {
      console.error(`generated id mismatch for micro-app ${name}: ${generatedId} != ${genid}`)
      return
    }
  }
  const __MICRO_APPS__ = window.__SPARKY_LIBS__ && window.__SPARKY_LIBS__.__MICRO_APPS__
  if (!__MICRO_APPS__) {
    console.error(`unable to load micro-app ${name} due to cannot detect sparky framework and micro-app script`, sig)
  }
  else {
    if (!globalVar && !(curScript && dev)) {
      console.warn(`fallback to use __MICRO_APPS__.${name} to export micro-app`)
    }
    __MICRO_APPS__[name] = module
  }
};
run('ai_firewall','ai_firewalluwT0jh9','','',entryModule,'__SPARKY_LIBS__.__MICRO_APPS__.ai_firewall');
